@import '../../styles/includes.scss';

@include themify($themes) {
    &.menu {      
        font-family: $noto-sans;

        .menu-paper.menu-paper {
            width: 100%;
            box-shadow: none;
            background-color: themed('bg-main-primary');

            @include screen(sm) {
                width: 520px;
                box-shadow: 0px 2px 4px 0px rgba(100,109,120,0.1), 
                        0px 2px 4px 0px rgba(100,109,120, 0.1),
                        0px 2px 4px 0px rgba(100,109,120,0.1);
                        border-radius: 0;
            }

            @include screen(lg) {
                width: 300px;
            }
            
        }

        .link.link {
            display: block;
            margin: 0 4px;
            padding-right: 24px; 
            padding-left: 24px;
            color: themed('text-secondary');
        }

        .menu-item {
            padding: 0;
            font-family: $noto-sans;
            color: themed('text-secondary');
            @include label-16($weight: 700);
            
            &:hover {
                background-color: transparent;
            }

            &:has(.button) {
                padding: 0 28px;
            }
        }


        .menu-item--user-account {
            justify-content: flex-end;
        }
    

        .menu-list {
            padding: 24px 0;
        }

        .subheader {
            display: flex;
            flex-direction: column;
            padding-top: 16px;

            @include screen(sm) {
                align-items: flex-start;
                padding-left: 32px;
            }

            .navigation-divider {
                display: none;
            }

            .subheader__text {
                display: block;
                order: 2;
                color: themed('text-tertiary');
                padding-left: 4px;
                margin-bottom: 8px;

                @include screen(sm) {
                    padding-left: 0;
                }
            }

            .social-media {
                display: flex;
                order: 2;
                justify-content: center;
            }

            .social-media__icon {
                width: 40px;
                height: 40px;
                margin-right: 8px;
            }

            .subheader__navigation {
                display: flex;
                order: 1;
                margin-bottom: 16px;

                @include screen(sm) {
                    display: none;
                }

                .link {
                    padding-left: 2px;
                    margin: 0 2px;
                    padding-right: 14px;

                    &:focus {
                        margin-left: 4px;
                    }
                }
            }
            
        }

        .button {
            margin-left: 26px;
        }
    }
}