@import '../../styles/includes.scss';


@include themify($themes) {

    .landscape-card {
        font-family: $noto-sans;
        border-radius: 8px;
        background-color: themed('bg-inverted-secondary');
        display: flex;
        cursor: pointer;

        .landscape-card__text-wrapper {
            padding: 24px 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include screen(sm) {
                padding: 24px 24px 0px;
            }
        }

        .landscape-card__surtitle {
            color: themed('text-primary');
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            font-weight: 700;
            line-height: $lh-24;
            font-size: $fs-16;
        }

        .landscape-card__content {
            color: themed('text-primary');
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .landscape-card__chip {
            color: themed('text-inverted-secondary');
            background-color: themed('bg-inverted-tertiary');
            margin-bottom: 8px;   
            width: fit-content;
            font-family: $noto-sans;
            font-weight: 700;
            font-size: $fs-10;

            .chip__label {
                padding-right: 12px;
            }
        }

        .landscape-card__chip-icon {
            color: themed('bg-main-tertiary');     
            margin-left: 8px;
        }

        .landscape-card__tag {
            font-size: $fs-13;
            line-height: $fs-16;
            font-weight: 400;
            color: themed('text-main-tertiary');
        }

        .landscape-card__image {
            width: 100%;
            @include screen(sm) {
                max-width: 340px;
                min-height: 227px;
            }
        }
    }

    .landscape-card-link {
        display: inline-block;
        text-decoration: unset;
    }

}