@import '../../styles/includes.scss';

@include themify($themes) {
    .cards-video-section {
        padding: 24px 0;
        color: themed('text-inverted-primary');

        @include screen(md) {
            padding: 64px 0;
        }

        @include screen(xxl) {
            max-width: 1280px;
            margin: auto;
        }

        .cards-video-section__title {
            text-align: center;
            margin-bottom: 24px;

            @include screen(md) {
                margin-bottom: 64px;
            }
        }

        .video-drawer__mobile {
            width: 100%;

            .video-drawer__wrapper {
                padding: 0;
            }

            @include screen(md) {
                display: none;
            }
        }

        .video-drawer__desktop {
            display: none;

            @include screen(md) {
                display: block;
            }
        }

        .card__wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 8px;

                @include screen(md) {
                    margin-bottom: unset;
                }
            }

            &:last-child {
                .card-link {
                    margin-bottom: unset;
                }
            }


            @include screen(md) {
                width: 25%;
                flex-direction: row;
            }

            .video-drawer__wrapper {
                margin-bottom: 24px;
            }
        }
    }
}