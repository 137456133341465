@import '../../styles/includes.scss';

@include themify($themes) {
    .main-header {
        padding: 16px 24px 0;
        width: 100%;
    
        @include screen(sm) {
            padding: 24px 32px 16px;
        }
    
        @include screen(md) {
            padding: 24px 80px 16px;
        }

        .link.link {
            text-transform: uppercase;
            font-weight: 700;
        }

        .main-header__wrapper {
            display: flex;
            justify-content: space-between;
        }

        .main-header__navigation {
            display: none;

            @include screen(sm) {
                display: flex;
                align-items: center;
            }

            .link--last {
                margin-right: 40px;
            }

            .button--primary, .button--secondary {
                @include screen(sm) {
                    padding: 14px 16px;
                }

                .button__text {
                    @include screen(sm) {
                        font-size: $fs-16;
                        line-height: $lh-20;
                    }
                }
            }

            .button--secondary {
                border: 2px solid;
                margin-right: 15px;

                @include screen(sm) {
                    padding: 12px 16px;
                }

                &:hover {
                    background-color: themed(text-tertiary);
                }
            }
        }

        .sub-navigation__menu {
            @include screen(sm) {
                display: none
            }

            &.open {
                @include screen(sm) {
                    padding-bottom: 12px;
                }
                border-bottom: 4px solid themed('color-accent-primary-regular');
            }
        }

        .sub-navigation__menu-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            padding: 12px 6px;
            color: themed('text-secondary');
            
            @include screen(sm) {
                padding: 12px;
                flex-direction: row;
                background-color: themed('color-neutral-secondary-medium');
                border-radius: 4px;

                .icon {
                    margin-right: 8px;
                }
            }

            @include screen(md) {
                display: none;
            }
        }

        .sub-navigation__menu-text {
            @include label-10($uppercase: true, $weight: 700);

            @include screen(sm) {
                @include label-13($uppercase: true, $weight: 700);
            }

        }
    }
}