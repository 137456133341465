@import '../../styles/includes.scss';

@include themify($themes) {
    .work-with-us-page {
        padding: 40px 24px 64px;

        @include screen(md) {
            padding: 64px 272px 128px;
        }

        @include screen(xxl) {
            max-width: 1280px;
        }

        color: themed('text-inverted-primary');

        .work-with-us-page__title {
            text-align: center;
            margin-bottom: 32px;
        }

        .work-with-us-page__information {
            padding-top: 32px;

            @include screen(md) {
                padding-top: 64px;
            }
        }

        .information__main-paragraph {
            font-size: $fs-18;
            line-height: $lh-29;
            font-weight: 400;
            margin-bottom: 24px;

            @include screen(md) {
                font-size: $fs-25;
                line-height: $lh-40;
            }
        }

        .information__sub-paragraph {
            font-size: $fs-13;
            line-height: $lh-21;
            font-weight: 400;
            color: themed('text-inverted-secondary');
            margin-bottom: 32px;
            display: block;
            
            @include screen(md) {
                font-size: $fs-18;
                line-height: $lh-29;
            }
        }

        .information__key-points {
            margin-bottom: 24px;
            font-size: $fs-13;
            line-height: $lh-21;
            font-weight: 400;
            color: themed('text-inverted-secondary');

            @include screen(md) {
                font-size: $fs-18;
                line-height: $lh-29;
            }
        }

        .button {

            @include screen(sm) {
                padding: 16px 24px;
            }
        }
    }
}