@import '../../styles/includes.scss';

@include themify($themes) {
    .logo {
        margin-right: 14px;
        width: 26px;

        @include screen(sm) {
            width: unset;
        }
    }
    
    .logo-wrapper {
        display: flex;
        align-items: center;
    }

    .logo__text {
        font-size: $fs-20;
        line-height: $lh-29;
        color: themed('color-inverted-primary-regular');
        display: flex;

    }

    .logo__divider {
        border-color: themed('color-inverted-primary-regular');
        margin-right: 14px;
    }

    .logo__link  {
        display: block;
        cursor: pointer;
    }

    .union {
        margin-right: 9px;
        img {
            height: 18px;

            @include screen(sm) {
                height: unset;
            }
        }
    }

    .vr-unit {

        img {
            height: 18px;

            @include screen(sm) {
                height: unset;
            }
        }
    }
}