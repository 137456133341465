@import '../../styles/includes.scss';

@include themify($themes) {
    .background-video__container {
        position: relative;
        width: 100%;

        .background-video {
            width: 100%;
            opacity: 0.65;
            min-height: 516px;
            object-fit: cover;

            @include screen(sm) {
                min-height: unset;
                max-height: 800px;
            }
        }

        .gradient-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 34%;
            background: linear-gradient(to top,  $neutral-900, rgba(0, 0, 0, 0));
        }
    
        .background-video__title {
            font-size: $fs-32;
            line-height: $lh-40;
            padding: 0 24px;
            font-weight: 900;
            text-align: center;
            position: absolute;
            top: 25%;
            color: themed('text-inverted-primary');
            
            @include screen(md) {
                font-size: $fs-79;
                line-height: $lh-92;
                padding: 0 80px;
            }

            @include screen(xxl) {
                max-width: 1280px;
                margin: 0 auto;
                left: 0;
                right: 0;
            }

        }

        .background-video__pause-container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 72px;
            background-color: themed('color-neutral-secondary-regular');
            color: themed('text-inverted-primary');
            position: absolute;
            bottom: 30px;
            right: 30px;
            border-radius: 50%;
            cursor: pointer;
            border: none;
        }

        .background-video__slide-down {
            display: none;

            @include screen(sm) {
                display: flex;
                color: themed('text-inverted-primary');
                border: 1px solid themed('text-inverted-primary');
                border-radius: 50%;
                width: 46px;
                height: 46px;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 50%;
                margin-top: 30px;
            }
        }

        .background-video__play-pause-wrapper {
            width: 72px;
            height: 72px;
            background-color: transparent;
            border: 0;
            color: themed('text-inverted-primary');
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        iframe {
            width: 100%;
            border: 0;
            aspect-ratio: 16 / 9;
        }
    }

}