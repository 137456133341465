@import '../../styles/includes.scss';

@include themify($themes) {
    .video-drawer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include screen(sm) {
            align-items: flex-start;
        }

        @include screen(md) {
            flex-wrap: nowrap;
            flex-direction: row;
        }

        .video-drawer__close {
            width: 44px;
            height: 44px;
            right: 30px;
            top: 22px;
            position: absolute;
            cursor: pointer;

            @include screen(sm) {
                width: 56px;
                height: 56px;
            }

            @include screen(xs, 'max') {
                right: 5px;
            }
        }

        iframe, video, .background-video {
            width: 312px;
            aspect-ratio: 16 / 9;
            border: 0;
            border-radius: 8px;
            object-fit: cover;
            cursor: pointer;

            @include screen(md) {
                width: 500px;
                height: 448px;
            }

            @include screen(xl) {
                width: 700px;
            }
        }

        .video-drawer__featured-projects {
            display: flex;
            flex-direction: column;

            .landscape-card {
                margin-bottom: 29px;
                align-items: center;
                box-shadow: none;
            }

            .landscape-card__image {
                border-radius: 8px;
                width: 152px;
                height: 100px;
            }

            .landscape-card__surtitle {
                margin-bottom: 0;
                font-size: $fs-13;
                line-height: $lh-16;
                
                @include screen(md) {
                    font-size: $fs-16;
                    line-height: $lh-20;
                }
            }

            .landscape-card__text-wrapper {
                padding: 0 0 0 12px;
                min-height: auto;

                @include screen(sm) {
                    padding: 0 0 0 24px;
                }
            }
        
            .button--secondary {
                border: 2px solid;

                @include screen(md) {
                    padding: 16px 24px;
                }
            }

        }

        .card__wrapper {
            width: 312px;
            position: relative;
            margin-bottom: 32px;

            @include screen(sm) {
                margin-right: 32px;
            }
            
            @include screen(md) {
                margin-bottom: unset;
                width: 500px;
            }
            
            @include screen(xl) {
                width: 700px;
            }
        }

        .gradient-overlay {
            height: 80px;
            position: absolute;
            bottom: 4px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            left: 0;
            width: 100%;
            background: $neutral-900;
            opacity: 0.66;
            
            @include screen(md) {
                height: 116px;
            }
        }

        .card__chip {
            color: themed('text-inverted-secondary');
            background-color: themed('bg-inverted-tertiary');
            margin-bottom: 8px;   
            width: fit-content;
            font-family: $noto-sans;
            font-weight: 700;
            font-size: $fs-10;

            .chip__label {
                padding-right: 12px;
                color: themed('text-inverted-secondary');
            }

            .card__chip-icon {
                color: themed('bg-main-tertiary');
            }
        } 

        .card__info {
            bottom: 16px;
            left: 16px;
            position: absolute;
            z-index: 20;

            @include screen(sm) {
                bottom: 32px;
                left: 32px;
            }
        }

        .card__title {
            font-size: $fs-16;
            line-height: $lh-20;
            display: block;
            color: themed('text-inverted-primary');
            font-weight: 700;

            @include screen(sm) {
                font-size: $fs-20;
                line-height: $lh-24;
            }
        }

        .card__link {
            display: block;
        }
    }

    .video-drawer__wrapper {

        @include screen(sm) {
            padding: 0 80px;
        }

        &.hidden {
            display: none;
        }
    }

    .video-drawer__container {
        padding: 32px 24px 48px;
        display: flex;
        flex-direction: column;
        background-color: themed('bg-inverted-secondary');
        position: relative;
        border-radius: 8px;

        @include screen(sm) {
            padding: 44px 32px 72px;
        }

        @include screen('custom', 'max', '359px') {
            padding: 32px 4px 48px;
        }

        .video-drawer__title {
            font-size: $fs-16;
            line-height: $lh-20;
            margin-bottom: 28px;
            font-weight: 700;
            padding-right: 52px;

            @include screen(sm) {
                font-size: $fs-20;
                line-height: $lh-24;
                padding-right: unset;
            }
        }
    }



}