//NEUTRAL PALETTE
$neutral-0: #FFFFFF;
$neutral-100: #F1F2F4;
$neutral-200: #D3D8DE;
$neutral-300: #A7B2BE;
$neutral-400: #818C98;
$neutral-main: #646D78; // 500
$neutral-700: #454D54;
$neutral-800: #21262C;
$neutral-900: #14181F;

//SAND PALETTE
$sand-100: #FAF7F3;
$sand-200: #F6EFE2;
$sand-300: #DED6CA;
$sand-main: #AFA498; // 500
$sand-700: #524B45;
$sand-800: #282827;
$sand-900: #1A1919;

//GREEN PALETTE
$green-100: #F1F4F3;
$green-200: #D3DEDB;
$green-300: #A1BAB3;
$green-500: #4C8072; 
$green-main: #355A50; // 700
$green-800: #264039;
$green-900: #162420;

//RED PALETTE
$red-100: #F7EDEF;
$red-200: #E6C6CB;
$red-300: #D298A1;
$red-500: #AD4D5B; 
$red-main: #9C1F31; // 700
$red-800: #6A1521;
$red-900: #400D14;

//BLUE PALETTE
$blue-100: #F0F8FF;
$blue-200: #D4E7F7;
$blue-300: #98C3E7;
$blue-500: #2675B5; 
$blue-main: #005BA4; // 700
$blue-800: #004379;
$blue-900: #00233F;

//ORANGE PALETTE
$orange-100: #FEF3E6;
$orange-200: #FFDCB3;
$orange-300: #FFB966;
$orange-500: #F0890D; 
$orange-main: #AA6009; // 700
$orange-800: #704410;
$orange-900: #452908;

//WHITE PALETTE
$white: #FFFFFF;

//BLACK PALETTE
$black: #000000;