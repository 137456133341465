@import '../../styles/includes.scss';

@include themify($themes) {
    .footer-wrapper {
        width: 100%;

        @include screen(xxl) {
            max-width: 1280px;
        }

        font-family: $noto-sans;
        #cookie-consent-banner {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 2147483645;
            box-sizing: border-box;
            width: 100%;
            background-color: themed('bg-inverted-secondary');
        }
          
        .cookie-consent-banner__inner {     
            max-width: 960px;
            margin: 0 auto;
            padding: 28px 24px;
        }
          
        .cookie-consent-banner__copy { 
            margin-bottom: 16px;
        }
          
        .cookie-consent-banner__header {
            margin-bottom: 8px;
            
            font-family: "CeraPRO-Bold", sans-serif, arial;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #FFF;
        }
          
        .cookie-consent-banner__description {
            font-family: "CeraPRO-Regular", sans-serif, arial;
            font-weight: normal;
            color: #838F93;
            font-size: 16px;
            line-height: 24px;
        }

        .cookie-consent-banner__cta {
            margin-right: 16px;
        }

        @media only screen and (max-width: 408px) {
            .cookie-consent-banner__actions {
                text-align: center;
               .cookie-consent-banner__cta {
                margin-bottom: 10px;
               }
            }
        }
          
        .cookie-consent-banner__cta {
            box-sizing: border-box;
            display: inline-block;
            min-width: 164px;
            padding: 11px 13px;
            border-radius: 2px;
            color: #FFF;
            text-decoration: none;
            text-align: center;
            font-family: "CeraPRO-Regular", sans-serif, arial;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: themed('color-accent-secondary-regular');
            background-color: themed('color-accent-primary-regular');
            border-color: themed('color-accent-primary-regular');
            cursor: pointer;
            &:hover {
                background-color: themed('color-accent-primary-light');
                border-color: themed('color-accent-primary-light');
            }
        }
          
        .cookie-consent-banner__cta--secondary { 
            padding: 9px 13px;
            border: 2px solid #3A4649;
            background-color: transparent;
            color: #FFF;
            cursor: pointer;
            &:hover {
                background-color: themed('color-accent-primary-light');
                border-color: themed('color-accent-primary-light');
            }
        }
    }

    .footer {
        background-color: themed('bg-main-primary');
        color: themed('text-inverted-primary');
        display: flex;
        flex-direction: column;
        padding: 24px 24px 48px;

        @include screen(sm) {
            padding: 64px 80px;
        }

        @include screen(md) {
            flex-direction: row;
            align-items: center;
        }

        .footer__content-title {
            margin-bottom: 24px;
        }

        .footer__content-text {
            font-weight: 400;
            font-size: $fs-13;
            line-height: $lh-20;
            color: themed('text-main-secondary');
            margin-bottom: 28px;

            @include screen(md) {
                font-size: $fs-18;
                line-height: $lh-29;
            }
        }

        .footer__image {
            height: 100%;
            margin-bottom: 32px;
            
            @include screen(md) {
                margin-right: 64px;
                margin-bottom: unset;
            }
        }

        .content-text__list-info {
            margin-bottom: 24px;
            display: block;
        }

        .button.button {

            @include screen(sm) {
                padding: 16px 24px;
            }
        }
    }

    .footer__sub-nav {
        background-color: themed('bg-main-primary');
        color: themed('text-inverted-primary');
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        font-size: $fs-13;
        line-height: $lh-16;
        
        @include screen(sm) {
            padding: 0 80px 14px;
            flex-direction: row;
        }

        .footer__copyright {
            margin-right: 16px;
        }

        .footer__link {
            color: themed('text-inverted-primary');
            text-decoration: underline;

            &.mr-16 {
                margin-right: 16px;
            }
        }
        
        .sub-nav__info {
            margin-bottom: 16px;

            @include screen(sm) {
                margin-bottom: 0;
            }
        }
    }
}
