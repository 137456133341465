@import '../../styles/includes.scss';

@include themify($themes) {
    .homepage {
        width: 100%;
        
        .card-list {
            display: flex;
            margin-bottom: 24px;
            flex-direction: column;
            align-items: center;
    
            @include screen(sm) {
                padding: 0 80px;
            }
            
            @include screen(md) {
                flex-direction: row;
                gap: 32px;
            }
    
            .card-link {
                width: calc(100% - 48px);
                margin-bottom: 16px;
    
                @include screen(sm) {
                    width: 100%;
                }
    
                @include screen(sm) {
                    margin-bottom: unset;
                }
            }
        }
    }
}