@import './typography';
@import './colors.scss';

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: $noto-sans;
    background-color: $neutral-900;
}

a {
    text-decoration: none;
}
  
p {
    margin: 0;
}

ul {
    padding-left: 28px;
    margin: 0;
}