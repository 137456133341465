@import './fonts';
@import './breakpoints';

//FONT FAMILIES
$noto-sans: 'Noto Sans', sans-serif;
$noto-serif: 'Noto Serif', serif;
$ibm-sans-arabic: 'IBM Plex Sans Arabic', sans-serif;

// 1REM = 16px

//FONT-SIZE
$fs-10: 0.625rem;
$fs-13: 0.8125rem;
$fs-16: 1rem;
$fs-18: 1.125rem;
$fs-20: 1.25rem;
$fs-24: 1.5rem;
$fs-25: 1.5625rem;
$fs-31: 1.9375rem;
$fs-32: 2rem;
$fs-39: 2.4375rem;
$fs-44: 2.75rem;
$fs-49: 3.0625rem;
$fs-61: 3.8125rem;
$fs-79: 4.9375rem;


//LINE HEIGHT
$lh-12: 0.75rem;
$lh-16: 1rem;
$lh-20: 1.25rem;
$lh-21: 1.3125rem;
$lh-24: 1.5rem;
$lh-26: 1.625rem;
$lh-29: 1.8125rem;
$lh-32: 2rem;
$lh-38: 2.375rem;
$lh-40: 2.5rem;
$lh-47: 2.9375rem;
$lh-48: 3rem;
$lh-53: 3.3125rem;
$lh-59: 3.6875rem;
$lh-60: 3.75rem;
$lh-92: 5.75rem;

@mixin fsize-lheight-fweight($fontSize: $fs-16, $lineHeight: $fs-20, $fontWeight: 400) {
    @if $fontSize {
        font-size: $fontSize;
    }
    @if $lineHeight {
        line-height: $lineHeight;
    }
    @if $fontWeight {
        font-weight: $fontWeight;
    }
}

h1 {
    margin: 0;
    @include fsize-lheight-fweight($fs-25, $lh-32, 600);
    @include screen(sm) {
        @include fsize-lheight-fweight($fs-31, $lh-38, 600);
    }
    @include screen(lg) {
        @include fsize-lheight-fweight($fs-39, $lh-47, 600);
    }
}

h2 {
    margin: 0;
    @include fsize-lheight-fweight($fs-20, $lh-26, 600);
    @include screen(sm) {
        @include fsize-lheight-fweight($fs-25, $lh-32, 600);
    }
    @include screen(lg) {
        @include fsize-lheight-fweight($fs-31, $lh-38, 600);
    }
}

h3 {
    margin: 0;
    @include fsize-lheight-fweight($fs-16, $lh-21, 600);
    @include screen(sm) {
        @include fsize-lheight-fweight($fs-20, $lh-26, 600);
    }
    @include screen(lg) {
        @include fsize-lheight-fweight($fs-25, $lh-32, 600);
    }
}

h4 {
    margin: 0;
    @include fsize-lheight-fweight($fs-13, $lh-16, 600);
    @include screen(sm) {
        @include fsize-lheight-fweight($fs-16, $lh-21, 600);
    }
    @include screen(lg) {
        @include fsize-lheight-fweight($fs-20, $lh-26, 600);
    }
}

@mixin label-10($uppercase: false, $weight: 400) {
    @include fsize-lheight-fweight($fs-10, $lh-12, $weight);
    @if $uppercase {
        text-transform: uppercase;
    }
}

@mixin label-13($uppercase: false, $weight: 400) {
    @include fsize-lheight-fweight($fs-13, $lh-16, $weight);
    @if $uppercase {
        text-transform: uppercase;
    }
}

@mixin label-16($uppercase: false, $weight: 400) {
    @include fsize-lheight-fweight($fs-16, $lh-20, $weight);
    @if $uppercase {
        text-transform: uppercase;
    }
}

@mixin label-20($uppercase: false, $weight: 400) {
    @include fsize-lheight-fweight($fs-20, $lh-24, $weight);
    @if $uppercase {
        text-transform: uppercase;
    }
}

@mixin body-small() {
    @include fsize-lheight-fweight($fs-13, $lh-21);
    @include screen(sm) {
        @include fsize-lheight-fweight($fs-16, $lh-26);
    }
    @include screen(lg) {
        @include fsize-lheight-fweight($fs-18, $lh-29);
    }
}

@mixin body-large() {
    @include fsize-lheight-fweight($fs-18, $lh-29);
    @include screen(sm) {
        @include fsize-lheight-fweight($fs-20, $lh-32);
    }
    @include screen(lg) {
        @include fsize-lheight-fweight($fs-25, $lh-40);
    }
}