@import '../../styles/includes.scss';

@include themify($themes) {
    .button.button {
        font-family: $noto-sans;
        text-transform: none;
        padding: 12px 16px;
        outline: none;
        border-radius: 4px;

            &.button--primary {
                color: themed('color-accent-secondary-regular');
                background-color: themed('color-accent-primary-regular');
                border-color: themed('color-accent-primary-regular');
    
                &:hover {
                    background-color: themed('color-accent-primary-light');
                    border-color: themed('color-accent-primary-light');
                }
    
                &:focus {
                    box-shadow: 0 0 0 4px themed('border-focus-grey');
                }
    
                &:active {
                    background-color: themed('color-accent-primary-strong');
                    border-color: themed('color-accent-primary-strong');
    
                    &:focus {
                        box-shadow: none;
                    }
                }
            }

             &.button--accent-secondary {
            color: themed('color-accent-primary-regular');
            background-color: transparent;
            border-color: themed('color-accent-primary-regular');

            &:hover {
                color: themed('color-accent-primary-light');
                background-color: themed('color-accent-secondary-medium');
                border-color: themed('color-accent-primary-light');
            }

            &:focus {
                box-shadow: 0 0 0 4px themed('border-focus-grey');
            }


            &:active {
                background-color: themed('color-accent-secondary-strong');
                border-color: themed('color-accent-primary-strong');
                color: themed('color-accent-primary-strong');

                &:focus {
                    box-shadow: none;
                }
            }
        }

        &.button--secondary {
            color: themed('text-inverted-secondary');
            background-color: transparent;
            border-color: themed('border-neutral-secondary-regular');

            &:hover {
                color: themed('text-inverted-secondary');
                background-color: themed('bg-neutral-primary-strong');
                border-color: themed('border-inverted-regular');
            }

            &:focus {
                box-shadow: 0 0 0 4px themed('border-focus-grey');
            }

            &:active {
                background-color: themed('bg-neutral-primary-strong');
                border-color: themed('border-inverted-strong');
                color: themed('text-inverted-secondary');

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .button__text {
        padding-left: 4px;
        font-size: $fs-16;
        line-height: $lh-20;
        font-family: $noto-sans;
        font-weight: 700;
        
        @include screen(md) {
            font-size: $fs-20;
            line-height: $lh-24;
        }
    }

    .button--medium.button--medium {
        padding: 12px 16px;
        .button__text {
            font-family: $noto-sans;
        }
    }

    .button--large {

        .button__icon.button__icon{
            font-size: $fs-24;
        }
    }
}