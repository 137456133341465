
// Responsive breakpoints
$breakpoints  : (
  xs          : 330px,
  sm          : 768px,
  md          : 992px,
  lg          : 1024px,
  xl          : 1280px,
  xxl         : 1440px
) !default;

$size-xs      : map-get($breakpoints, 'xs');
$size-sm      : map-get($breakpoints, 'sm');
$size-md      : map-get($breakpoints, 'md');
$size-lg      : map-get($breakpoints, 'lg');
$size-xl      : map-get($breakpoints, 'xl');
$size-xxl      : map-get($breakpoints, 'xxl');

@mixin screen($size, $type: min, $pixels: map-get($breakpoints, 'sm')) {
  @if $size != 'custom' {
    @media screen and ($type + -width: map-get($breakpoints,$size)) {
      @content;
    }
  } @else {
    @media screen and ($type + -width: $pixels) {
      @content;
    }
  }
}

//    *** USAGE ***
//    
//    .some-class {
//      @include screen(sm) {
//        ...styles
//      }
//    }
//    
//    .some-class {
//      @include screen(sm, 'max') {
//        ...styles
//      }
//    }
//    
//    .some-class {
//      @include screen('custom', 'min', '375px') {
//        ...styles
//      }
//    }