@import '../../styles/includes.scss';

@include themify($themes) {
    .featured-projects-page {
        padding: 48px 0 64px;
        
        @include screen(sm) {
            padding: 64px 0;
        }

        @include screen(xxl) {
            max-width: 1280px;
        }

        .featured-projects-page__title {
            color: themed('text-inverted-primary');
            margin-bottom: 32px;
            text-align: center;
        }
    
        .landscape-card-list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 24px;
            margin-bottom: 32px;
            justify-content: center;
            flex-direction: column;
            gap: 24px;
            
            @include screen(sm) {
                flex-direction: row;
                gap: 32px;
            }

            @include screen(xl) {
                padding: 0 80px;
            }
    
            .landscape-card {
                width: 100%;
                border: 1px solid themed('border-inverted-light');
                flex-direction: column;

                &:hover {
                    box-shadow: 0 0 0 1px themed('border-accent-regular');
                    border: 1px solid themed('border-accent-regular');
                }

                @include screen(sm) {
                    width: 544px;
                    flex-direction: row;
                }
            }

            .landscape-card__link {
                width: 100%;

                @include screen(sm) {
                    width: 544px;
                }
            }

            .landscape-card__wrapper {
                width: 100%;

                @include screen(sm) {
                    width: 544px;
                }
            }
        }
    
        .pagination.pagination {
            padding-top: 16px;
            .MuiButtonBase-root {
                color: themed('text-main-secondary');
                &.Mui-selected {
                    background-color: themed('bg-inverted-primary');
                    color: themed('bg-main-primary');
                }
            }
    
            .MuiPagination-ul {
                justify-content: center;
            }
        }

        .featured-projects-page__filters {
            text-align: center;
            margin-bottom: 32px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: center;

            @include screen(sm) {
                display: block;
            }
        }
        
        .featured-projects-page__filter {
            font-family: $noto-sans;
            background-color: themed('bg-main-primary');
            color: themed('text-main-secondary');
            border: 1px solid themed('border-neutral-regular');
            cursor: pointer;
            font-size: $fs-16;
            line-height: $lh-20;
            padding: 12px 0;
            border-radius: 50px;
            height: auto;
            font-weight: 400;

            &:hover {
                box-shadow: 0 0 0 1px themed('border-accent-regular');
                border-color: themed('border-accent-regular');
            }

            &:active {
                box-shadow: 0 0 0 2px themed('border-accent-regular');
            }

            &.selected {
                &:focus {
                    border-color: themed('border-accent-regular');
                    box-shadow: 0 0 0 1px themed('border-accent-regular');
                }
            }

            .filter__label {
                padding: 0 16px;
            }

            &:not(:last-child) {
                @include screen(sm) {
                    margin-right: 8px;
                }
            }
        }
    }

}