@import '../../styles/includes.scss';


    .video-modal.video-modal.video-modal {
        background-color: transparent;
        width: 100%;
        overflow-y: unset;
        max-width: calc(100% - 48px);
        margin: 0;
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        
        @include screen(sm) {
            max-width: calc(100% - 160px);
        }

        .video-modal__close-wrapper {
            display: flex;
            justify-content: flex-end;
            color: $neutral-0;
            margin-bottom: 17px;
            position: absolute;
            top: 0;
            right: 0;

            @include screen(sm) {
                position: relative;
                top: unset;
                right: unset;
            }
        }

        .video-modal__close-circle {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include screen(sm) {
                background-color: $neutral-800;
                opacity: 0.66;
            }
        }

        .video-modal__close-icon {
            width: 52px;
            height: 52px;
            cursor: pointer;
        }

        .video-modal__player-wrapper {
            display: flex;
            justify-content: center;
        }

        iframe {
            aspect-ratio: 16 / 9;
            border: 0;
            height: auto;
            cursor: pointer;
            width: 100%;
            
            @include screen(sm) {
                width: calc(100% - 192px);
            }

            @include screen('custom', 'min', '1800px') {
                width: calc(85% - 192px);
            }
        }
    }