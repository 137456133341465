@import '../../styles/includes.scss';

@include themify($themes) {
    .article-cover {
        font-family: $noto-sans;
        padding: 24px 24px 48px;

        @include screen(md) {
            display: flex;
            align-items: center;
            padding: 32px;
        }

        @include screen(lg) {
            padding: 64px 80px;
        }

        .article-cover__title {
            color: themed('text-inverted-primary');
            margin-bottom: 16px;
            
            @include screen(sm, 'max') {
                font-size: $fs-20;
                line-height: $lh-26;
            }
        }

        .article-cover__subtitle {
            font-size: $fs-13;
            line-height: $lh-16;
            color: themed('text-inverted-tertiary');
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 8px;

            @include screen(sm) {
                font-size: $fs-16;
                line-height: $lh-20;
            }
        }
    
        .article-cover__content {
            font-size: $fs-13;
            line-height: $lh-20;
            color: themed('text-inverted-secondary');
            font-weight: 400;
            margin: 0;

            @include screen (sm) {
                font-size: $fs-18;
                line-height: $lh-29;
            }
        }
        
        .article-cover__figure {
            margin: 0;
            margin-bottom: 24px;
            
            @include screen(md) {
                margin-right: 32px;
                margin-bottom: 0;
            }
        }

        .article-cover__image {
            width: 100%;
            
            @include screen(md) {
                width: 544px;
            }
        }

        .article-cover__image-caption {
            color: themed('text-tertiary');
        }

        .article-cover__text-wrapper {
            flex-shrink: 1.5;

            @include screen(sm) {
                padding-left: 16px;
            }

            @include screen(lg) {
                flex-shrink: 2;
            }
        }

        &.article-cover--right {
            display: flex;
            flex-direction: column;

            @include screen(md) {
                flex-direction: row;
            }

            .article-cover__figure {
                margin-right: 0;

                @include screen(md) {
                    order: 2;
                }
            }

            .article-cover__text-wrapper {
                
                @include screen(md) {
                    margin-right: 16px;
                    padding-left: 0;
                    padding-right: 16px;
                }
            }
        }
    }

}