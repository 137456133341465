@import '../../styles/includes.scss';

@include themify($themes) {
    .article-page {
        padding-top: 40px;
        width: 100%;
        
        @include screen(sm) {
            padding-top: 64px;
        }

        @include screen(xxl) {
            max-width: 1280px;
        }

        .article-page__type {
            display: block;
            color: themed('text-inverted-tertiary');
            text-align: center;
            margin-bottom: 8px;
            font-size: $fs-13;
            line-height: $lh-16;

            @include screen(sm) {
                font-size: $fs-16;
                line-height: $lh-20;
            }
        }

        .article-page__title {
            color: themed('text-inverted-primary');
            text-align: center;
            margin-bottom: 32px;
        }

        .article-page__more-projects {
            padding: 48px 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            @include screen(md) {
                padding: 64px 80px;
                text-align: start;
                display: block;
            }

            .more-projects__title {
                color: themed('text-inverted-primary');
                margin-bottom: 32px;
            }
    
            .more-projects__articles {
                margin-bottom: 32px;
                display: flex;
                gap: 24px;
                flex-wrap: wrap;
                justify-content: space-between;
                
                @include screen(md) {
                    gap: 32px;
                }
    
                .landscape-card {
                    border: 1px solid themed('border-inverted-light');
                    flex-direction: column;


                    &:hover {
                        box-shadow: 0 0 0 1px themed('border-accent-regular');
                        border: 1px solid themed('border-accent-regular');
                    }

                    @include screen(md) {
                        flex-direction: row;
                    }
                }

                .landscape-card__link {
                    width: 100%;

                    @include screen(md) {
                        width: calc(50% - 16px);
                    }
                }
            }

            .button--secondary {
                border: 2px solid;
            }

        }

        .video-content {
            margin-bottom: 32px;

            @include screen(sm) {
                margin-bottom: 64px;
            }

            iframe {
                cursor: pointer;
                width: 100%;
    
                @include screen(sm) {
                    width: calc(100% - 160px);
                }
            }
        }

    }

}