@import '../../styles/includes.scss';

.video-content {
  width: 100%;

  .video-content__aspect-ratio {
    display: flex;
    justify-content: center;
  }

  iframe {
    aspect-ratio: 16 / 9;
    width: calc(100% - 160px);
    height: auto;
    border: none;
    cursor: pointer;
  }
}

.video-content-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        margin-bottom: 15px;
}
