@import '../../styles/includes.scss';

@include themify($themes) {

    .header {
        display: flex;
        width: 100%;
        position: relative;

        &.header--homepage { 
            position: absolute;
            z-index: 20;
        }
        
        .subheader {
            padding: 16px 0;

            @include screen(sm) {
                padding: 16px 32px;
            }

            @include screen(lg) {
                padding: 16px 80px;
            }
        }
    }
}