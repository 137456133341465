@import '../../styles/includes.scss';


@include themify($themes) {

    .card {
        font-family: $noto-sans;
        border-radius: 16px;
        background-color: themed('bg-inverted-secondary');
        border: 1px solid themed('border-inverted-light');

        @include screen(md) {
            &:hover {
                border: 2px solid themed('border-accent-regular');
    
                .card__text-wrapper {
                    border-bottom: 17px solid themed('border-accent-regular');
                }
            }    

            .active {
                border-bottom: 17px solid  themed('border-accent-regular');
        
                .card__text-wrapper {
                    border-bottom: unset;
                }
            }
        }

        .card__text-wrapper {
            padding: 24px 24px 0px;
            min-height: 92px;
            border-bottom: 17px solid themed('bg-inverted-secondary');

            @include screen(md) {
                height: 203px;
            }

        }

        .card__surtitle {
            font-size: $fs-16;
            line-height: $lh-20;
            color: themed('text-primary');
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            font-weight: 700;

            @include screen(md) {
                font-size: $fs-20;
                line-height: $lh-24;

            }
        }

        .card__content {
            color: themed('text-main-secondary');
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            font-size: $fs-13;
            line-height: $lh-16;

            @include screen(md) {
                font-size: $fs-16;
                line-height: $lh-20;
            }
        }

        .card__image {
            min-height: 140px;

            @include screen(sm) {
                object-fit: cover;
            }
        }
    }

    .card-link {
        display: inline-block;
        cursor: pointer;
        text-decoration: unset;
    } 

    .active {
        border-bottom: 17px solid  themed('border-accent-regular');

        .card__text-wrapper {
            border-bottom: unset;
        }
    }
}